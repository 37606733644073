<template lang="pug">
app-layout
  .page-faq-section(v-if="faqSections")
    .page-faq-section__toolbar.px-1.mb-6
      ui-btn(plain :to="$path.faq()" width=60)
        ui-icon.c-black(name="arrow-left" left)
        .c-text Помощь
    template(v-if="section")
      .t-h1-mobile.mb-6 {{ section.title }}
      faq-questions-list(:questions="section.questions")

      .t-h2-mobile.mb-4.mt-12 Разделы
      .faq-sections-list
        router-link.faq-sections-list__item(
          :class="{ 'faq-sections-list__item--active': section.active }"
          v-for="section in otherSections"
          :key="section.id"
          :to="$path.faqSection(section.id)"
        ) {{ section.title }}
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import AppLayout from '@/layouts/AppLayout';
  import { UiIcon, UiBtn, UiScrollHere } from '@/uikit';

  import FaqQuestionsList from '@/components/FaqQuestionsList';

  import { service as API } from '@/services/pages';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageFAQSection', 'blue');

  export default {
    name: 'page-faq-section',
    components: { FaqQuestionsList, UiIcon, UiBtn, UiScrollHere, AppLayout },

    props: {
      faqSectionId: { type: String, required: true },
    },

    computed: {
      section() {
        if (this.faqSections) {
          return this.faqSections.find((s) => s.id === this.faqSectionId);
        }
        return [];
      },

      otherSections() {
        if (this.faqSections) {
          return this.faqSections.map((s) => {
            return { ...s, active: s.id === this.faqSectionId };
          });
        }
        return [];
      },

      ...mapGetters(['faqSections']),
    },

    mounted() {
      if (!this.faqSections) this.fetchFaq();
    },

    methods: {
      async fetchFaq() {
        try {
          this.loading = true;
          const { sections } = await API.faq();
          this.setFaqSections(sections);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },
      ...mapActions(['setFaqSections']),
    },
  };
</script>

<style lang="scss">
  .page-faq-section {
    padding: 24px 16px;

    &__toolbar {
      display: flex;
      align-items: center;
    }
  }

  .faq-sections-list {
    .faq-sections-list__item {
      @include text-style('caption-normal', 'light');
      display: block;
      padding: 8px 0;
      color: c('text', 'light') !important;
      text-decoration: none;
    }

    .faq-sections-list__item.faq-sections-list__item--active {
      font-weight: 500;
      color: c('primary', 'light') !important;
    }
  }
</style>
