export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    about: {
      request: {
        method: 'get',
        url: '/pages/about.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    faq: {
      request: {
        method: 'get',
        url: '/pages/faq.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    blog: {
      request: {
        method: 'get',
        url: '/articles.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    articles: {
      request: {
        method: 'get',
        url: '/articles/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};
