import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class PagesService extends BaseApi {
  async about() {
    const { data: about } = await this.fetch('about');
    return about;
  }

  async faq() {
    const {
      data: { sections, featured },
    } = await this.fetch('faq');
    return { sections, featured };

    // sections: [
    //   {
    //     id: '',
    //     title: 'Название секции',
    //     questions: [
    //       { title, content },
    //       ...
    //     ]
    //   }
    // ]
    // featured: {
    //   title: 'Часто-задаваемые вопросы',
    //   questions: [
    //     { title, content },
    //     ...
    //   ]
    // }
  }

  async blog(page) {
    const { data } = await this.fetch('blog', { page: page });
    return data;
  }

  async articles(id) {
    const { data } = await this.fetch('articles', null, { id: id });
    return data;
  }
}

const service = new PagesService(apiSchema, axiosProvider);

export { PagesService };
export { service };
export default service;
