<template lang="pug">
ui-expansion-panels.faq-questions-list(v-if="questions && questions.length")
  ui-expansion-panel.faq-questions-list__item(v-for="(question, i) in questions" :key="i")
    template(#header)
      .faq-questions-list__item-title {{ question.title }}
    .faq-questions-list__item-content(v-html="question.answer")
</template>

<script>
  import UiExpansionPanel from '@/uikit/components/UiExpansionPanel';
  import UiExpansionPanels from '@/uikit/components/UiExpansionPanels';

  export default {
    name: 'faq-questions-list',
    components: { UiExpansionPanel, UiExpansionPanels },

    props: {
      questions: { type: Array, default: undefined },
    },
  };
</script>

<style lang="scss">
  .faq-questions-list {
    width: 100%;
  }

  .faq-questions-list__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid c('black40', 'light');

    &:first-child {
      border-top: 1px solid c('black40', 'light');
    }

    > .ui-expansion-panel__header {
      display: flex;
      align-items: flex-start;
      padding: 16px 0;

      &:hover,
      &.ui-dropdown-button--active {
        transition: color 0.2s;
        color: c('primary', 'light');
      }
    }
  }

  .faq-questions-list__item-title {
    @include text-style('h4-mobile', 'light');
  }

  .faq-questions-list__item-content {
    @include text--normal;
    // margin-top: 8px;
    // padding: 0 0 16px 0;
    color: c('text', 'light');

    ul,
    ol,
    p {
      margin-bottom: 16px;
    }

    ul {
      list-style: square;
    }

    ol {
      margin-left: 40px;
      list-style: decimal;
    }

    ul li,
    ol li {
      margin-bottom: 8px;
    }

    a {
      color: c('primary', 'light');
      text-decoration: underline;
      overflow-wrap: break-word;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }
  }
</style>
